@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 255, 255, 255;
  --background-rgb: 7, 14, 29;

  height: -webkit-fill-available;
}

html,
main {
  height: -webkit-fill-available;
}

body {
  height: -webkit-fill-available;
  color: rgb(var(--foreground-rgb));
  background: rgb(var(--background-rgb));
}

#datesnavbackground {
  background-image: url('../public/bg-main.webp');
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-size: cover;
}